import "./App.css";
import BandSvg from "./band.svg";
import Image from "./img.png";
import ArrowTopRight from "./arrow-top-right.svg";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export default function App() {
    const [walletAddress, setWalletAddress] = useState("");
    const [responseMessage, setResponseMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [recaptchaResponse, setRecaptcahResponse] = useState("");

    const handleAddressChange = (event) => {
        // Update the walletAddress state when the input value changes
        setWalletAddress(event.target.value);
    };

    const handleCaptchaResponseChange = (response) => {
        setRecaptcahResponse(response);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = {
            address: walletAddress,
            recaptchaResponse,
        };

        try {
            setResponseMessage("We are sending your testnet tokens. Due to large demands, it could take up to 1 minutes.")
            setErrorMessage("");
            const response = await fetch('https://faucet.testnet.inco.org/api/get-faucet', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            console.log("response:", response);

            if (response.ok) {
                const responseData = await response.json(); 
                console.log("Response Data:", responseData);
                setResponseMessage(responseData.message || "INCO test tokens coming your way!");
                setErrorMessage("");
            } else if (response.status === 429) {
                console.error("Rate limit exceeded. Please wait before making more requests.");
                setResponseMessage("");
                setErrorMessage("Rate limit exceeded. Please wait before making more requests.");
            } else if (response.status === 400) {
                const responseData = await response.json(); 
                setResponseMessage("");
                setErrorMessage(responseData.message || "An error occurred. Please try again later.");
            } else {
                // Handle other errors
                console.error("Request failed with status:", response.status);
                const errorResponse = await response.json();
                console.error("Error Response:", errorResponse);
                setErrorMessage("An error occurred. Please try again later.");
            }
        } catch (error) {
            console.log("err:", error);
            // Handle any network or other errors that occur during the request
            console.error("An error occurred:", error);
            // You can display an error message or take other actions here
            setResponseMessage(error.message);
        }
    };

    return (
        <div className="flex flex-col sm:flex-row items-center justify-center m-auto h-[100vh]">
            <div className="flex flex-col h-full flex-1 ">
                <div className={"flex flex-col sm:p-20 px-3 py-6"}>
                    <div>
                        <img src={BandSvg} alt="Band"/>
                    </div>
                    <div className={"flex flex-col justify-center sm:w-[560px] w-full mx-auto p-3"}>
                        <h1 className="mt-28 text-6xl font-bold text-black">Faucet</h1>
                        <p className="mt-6 text-black max-w-xl text-xl">
                            Get some INCO test tokens.
                        </p>
                        {responseMessage && (
                            <p className="mt-6 text-blue">{responseMessage}</p>
                        )}
                        {errorMessage && <p className="mb-4 text-red-500">{errorMessage}</p>}
                        <form onSubmit={handleSubmit} className={'flex flex-col mt-10'}>
                            <div className="text-sm text-gray-500">
                                <p>This is NOT an incentivized testnet, please DO NOT farm tokens.</p>
                            </div>
                            <input
                                type="text"
                                placeholder="0xAD..."
                                value={walletAddress}
                                onChange={handleAddressChange}
                                className="border border-blue-light rounded-md px-4 py-2 mb-1 w-full text-xl"
                            />
                            <ReCAPTCHA
                                sitekey="6Ld-350pAAAAADnbxhebSYVM18ulkM2AhH1B2g80"
                                onChange={handleCaptchaResponseChange}
                            />
                            <button
                                type="submit"
                                className="mt-6 w-full bg-blue hover:bg-blue-dark text-white text-xl font-bold py-2 px-4 rounded mb-8"
                            >
                                Submit
                            </button>
                        </form>
                        <a
                            href="https://docs.inco.org/getting-started/connect-metamask"
                            target="_blank"
                            className="flex flex-row items-center text-lg mb-16 text-blue hover:text-blue-dark"
                        >
                            Add Inco Network to Wallet
                            <span> <img src={ArrowTopRight} alt={"Arrow Top Right"}/> </span>
                        </a>

                    </div>
                </div>
            </div>
            <div className="flex flex-col text-center justify-center items-center w-full h-[50%] sm:h-full sm:w-[50%] bg-[url('/src/img.png')] bg-cover">
            </div>
        </div>
    );
}